import React from 'react';
import {graphql} from 'gatsby';
import {Intro, DynamicZone, StoryHeader, SEO} from '../components/organisms';
import {StoryPreview} from '../components/organisms/blocks';
import styled from 'styled-components';
import {GridComponentContainer, H2, TextContainer} from '../components/atoms';
import {colors, devices} from '../styles/theme';
import {PageContent} from '../components/organisms/Layout';

const RelatedContainer = styled(GridComponentContainer)`
  display: grid;
  grid-template-columns: 1fr;
  @media ${devices.tabletPortrait} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${devices.tabletLandscape} {
    grid-template-columns: repeat(6, 1fr);
  }
  @media ${devices.desktop} {
    grid-template-columns: repeat(8, 1fr);
  }
`;

const RelatedHeading = styled(TextContainer)`
  grid-column: span 8;
  color: ${colors.blue};
`;

const Story = ({data}) => {

  const {strapi: {story: {
    tags,
    storyImage,
    createdAt,
    title,
    intro,
    content,
    relatedStories,
    SEO: _SEO
  }}} = data;

  return (
    <PageContent>
      <SEO
        title={_SEO?.title}
        description={_SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: _SEO?.keywords,
          },
          {
            property: 'og:image',
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${storyImage?.file?.publicURL}`
          }
        ]}
      />
      <StoryHeader
        tags={tags}
        storyImage={storyImage}
        createdAt={createdAt}
        title={title}
      />
      <Intro intro={intro} />
      <DynamicZone blocks={content} />
      {relatedStories.length > 0 && (
        <RelatedContainer>
          <RelatedHeading>
            <H2>Related stories</H2>
          </RelatedHeading>
          {relatedStories.map(related => (
            <StoryPreview key={related.id} story={related}/>
          ))}
        </RelatedContainer>
      )}
    </PageContent>
  );
};

export default Story;

export const storyQuery = graphql`
  query GET_STORY($id: ID!) {
    strapi {
      story(id: $id) {
        SEO {
          ...SEO
        }
        ...Story
        ...RelatedStories
        content {
          __typename
          ...Carousel
          ...ContactUs
          ...ContactPersonBlock
          ...MediaWithText
          ...MediaBlock
          ...PlainText
          ...Share
          ...Testimonial
          ...USPBlock
          ...Download
        }
      }
    }
  }
`;
